export const menu = [
    {
        name: "My Applications",
        link: "/user",
        icon: <i class="fa-regular fa-file mr-7"></i>
    },
    {
        name: "Admin",
        link: "/user/admin",
        icon: <i class="fa-solid fa-user-tie mr-7"></i>
    },
    {
        name: "Applied Jobs",
        link: "/user/applied",
        icon: <i class="fas fa-briefcase mr-7"></i>
    },
    {
        name: "Saved Jobs",
        link: "/user/saved",
        icon: <i class="fas fa-suitcase mr-7"></i>
    },
    {
        name: "Payment",
        link: "/user/payment",
        icon: <i class="fa-solid fa-credit-card mr-7"></i>
    },
    {
        name: "Payment History",
        link: "/user/history",
        icon: <i class="fa-solid fa-rectangle-list mr-7"></i>
    },
    {
        name: "Settings",
        link: "/user/settings",
        icon: <i class="fas fa-cog mr-7"></i>
    },
    {
        name: "Log Out",
        link: "/",
        icon: <i class="fa-solid fa-right-from-bracket mr-7"></i>
    },
]
export const adminMenu = [
    {
        name: "Dashboard",
        link: "/user/admin",
        icon: <i class="icon icon-layout-11 mr-7"></i>
    },
    {
        name: "Applications",
        link: "/user/admin/application",
        icon: <i class="fas fa-briefcase mr-7"></i>
    },
    {
        name: "Users",
        link: "/user/admin/users",
        icon: <i class="fas fa-user mr-7"></i>
    },
    {
        name: "Interview",
        link: "/user/admin/interview",
        icon: <i class="fa-solid fa-comment mr-7"></i>
    },
    {
        name: "Appointment",
        link: "/user/admin/appointment",
        icon: <i class="fa-solid fa-calendar-check mr-7"></i>
    },
    {
        name: "Billing",
        link: "/user/admin/billing",
        icon: <i class="fa-solid fa-credit-card mr-7"></i>
    },
    {
        name: "Uploaded Documents",
        link: "/user/admin/images",
        icon: <i class="fa-solid fa-image mr-7"></i>
    },
    {
        name: "Payment",
        link: "/user/admin/payment",
        icon: <i class="fa-solid fa-file-invoice mr-7"></i>
    },
    {
        name: "Messages",
        link: "/user/admin/message",
        icon: <i class="fa-solid fa-envelope mr-7"></i>
    },
    {
        name: "Settings",
        link: "/user/admin/settings",
        icon: <i class="fas fa-cog mr-7"></i>
    },
    {
        name: "User",
        link: "/user/",
        icon: <i class="fa-solid fa-right-from-bracket mr-7"></i>
    },
]