import React, { useEffect, useState } from 'react';
import useGetPrivateData from '../../../hooks/useGetPrivateData';
import { Modal } from 'antd';
import Detail from '../../job/comp/detail';
import usePostPrivate from '../../../hooks/usePostPrivate';
import AlertComp from '../../../components/comps/alert';
import Preloader from '../../../components/comps/preloader';
import NoDataCard from '../../../components/comps/nodata';
import { useSearchParams } from 'react-router-dom';
const Editjobs = () => {
    const { getData } = useGetPrivateData()
    const [page, setpage] = useState(1)
    const [data, setdata] = useState()
    const [open, setopen] = useState(false)
    const [openEdit, setopenEdit] = useState(false)
    const [hasMore, sethasMore] = useState(false)
    const [active, setactive] = useState()
    const [edit, setEdit] = useState("")
    const [amount, setAmount] = useState("")
    const type = useSearchParams()[0].get("type")
    async function fetchJobs(params) {
        await getData("admin/jobs?page=" + page + "&job=" + (type !== "remote" ? null : "remote"), (d) => { setdata(d.data); sethasMore(d.hasMore) })
    }
    const { postData } = usePostPrivate()
    async function sendToTG(params) {
        const { _id, title, type, company, location } = data[active]
        const d = {
            edit, _id, title, type, company, location, payment: amount
        }
        await postData("admin/post", { ...d })
        setEdit("")
        setAmount("")
        setactive()
        setopenEdit(false)
    }
    useEffect(() => {
        fetchJobs()
    }, [page])
    useEffect(() => {
        if (active !== undefined && openEdit) {
            setEdit(data[active]?.detail)
        }
    }, [active, openEdit])
    useEffect(() => {
        const mod = document.getElementsByClassName("ant-modal-content")
        if (mod && open || mod && openEdit) {
            mod[0]?.classList.add("px-0")
        }
    }, [open, openEdit])
    return (
        <>  <div class="mt-7" id="dashboard-body">
            <Preloader open={!data} />
            <div class="container">
                <div class="mb-14 d-flex flex-column align-items-center">
                    <h6 className='my-3 text-center'>Edit Jobs and Post </h6>
                    <AlertComp />
                    {data && data?.length > 0 ? <div class="bg-white shadow-8 pt-7 rounded pb-8 px-11">
                        <div className='w-100 my-10 ml-7'>
                            <a class="btn btn-primary text-uppercase font-size-3" href={type === "remote" ? "/user/admin" : "/user/admin?type=remote"}>{type === "remote" ? "See Driving Jobs" : "See Remote Jobs"}</a>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                    {data.map((item, index) =>
                                        <tr role='button' onClick={() => { setactive(index); setopen(true) }} class="border border-color-2">
                                            <th scope="row" class="pl-6 border-0 py-7 pr-0">
                                                <span
                                                    class="media min-width-px-235 align-items-center"
                                                >
                                                    <div class="circle-36 mr-6">
                                                        <img
                                                            src={item.logo}
                                                            alt=""
                                                            class="w-100"
                                                        />
                                                    </div>
                                                    <h4
                                                        class="font-size-4 mb-0 font-weight-semibold text-black-2"
                                                    >
                                                        {item.title}
                                                    </h4>
                                                </span>
                                            </th>
                                            <td class="table-y-middle py-7 min-width-px-100 pr-0">
                                                <div class="">
                                                    <span
                                                        onClick={(e) => { e.stopPropagation(); setactive(index); setopenEdit(true) }}
                                                        role='button'
                                                        class="font-size-3 font-weight-bold text-red-2 text-uppercase">Edit</span>
                                                </div>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                        </div>
                        {hasMore && <div class="pt-2">
                            <button onClick={() => setpage((p) => p + 1)} className='btn btn-primary'>Next</button>
                        </div>}
                    </div> : <NoDataCard />}
                </div>
            </div>
        </div>

            <Modal
                style={{ top: 20, }}
                open={open}
                onCancel={() => { setactive(); setopen(false); }}
                footer={() => null}
            >
                {data?.length > 0 && <Detail data={data[active]} />}
            </Modal>
            <Modal
                style={{ top: 20, }}
                open={openEdit}
                onCancel={() => { setactive(); setopenEdit(false); }}
                footer={() => <button disabled={!edit || !amount} onClick={sendToTG} className='btn btn-primary mr-7'>Send</button>}
            >
                <div className='px-7 py-10'> <textarea required onChange={(e) => setEdit(e.target.value)} className='form-control' rows={25} value={edit} />
                    <input placeholder='Enter amount' required onChange={(e) => setAmount(e.target.value)} className='form-control mt-6' value={amount} />
                </div>
            </Modal>
        </>
    );
};

export default Editjobs;
